<template>
  <div>

    <b-row
      v-for="item in data.resumoSaldos[0].perfis"
      :key="item.perfil"
    >
      <b-col lg="12">
        <b-card
          class="card-statistics"
        >
          <b-card-body>
            <b-row>
              <b-col
                xl="3"
                sm="6"
                class="mb-md-0 mb-xl-0"
              >
                <b-media no-body>
                  <b-media-aside
                    class="mr-2"
                  >
                    <b-avatar
                      src="https://cryptoboot.com.br/repo/icons/usdt.png"
                      size="45px"
                    />
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    {{ $t('futures.meuAgente.saldoUSDT') }}
                    <h2 class="font-weight-bolder">
                      {{ toFixed(data.resumoSaldos[0].saldoTotal, 2) }}
                    </h2>
                  </b-media-body>
                </b-media>
              </b-col>
            </b-row>
          </b-card-body>
          <b-card-body class="statistics-body">
            <b-row>

              <b-col
                xl="3"
                sm="6"
                class="mb-md-0 mb-xl-0"
              >
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  v-b-tooltip.hover.top="$t('futures.meuAgente.valorEntradaOperacaoTooltip')"
                  variant="flat-info"
                  class="btn-icon"
                  block
                >
                  <b-media no-body>
                    <b-media-aside

                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="light-info"
                      >
                        <feather-icon
                          size="24"
                          icon="ShoppingCartIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        <template v-if="item.tipoValor == 'I'">
                          <small>({{ $t('futures.meuAgente.seguindoAgente') }})</small>
                        </template>
                        <template v-else>
                          {{ toFixed(item.operacao, 2) }}
                        </template>
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t('futures.meuAgente.valorEntradaOperacao') }}

                      </b-card-text>
                      <b-progress
                        :value="item.operacaoPerc"
                        max="100"
                        class="progress-bar-info"
                        variant="info"
                      />
                    </b-media-body>
                  </b-media>
                </b-button>
              </b-col>

              <b-col
                xl="3"
                sm="6"
                class="mb-md-0 mb-xl-0"
              >
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  v-b-tooltip.hover.top="$t('futures.meuAgente.saldoDisponivelOperarTooltip')"
                  variant="flat-success"
                  class="btn-icon"
                  block
                >
                  <b-media no-body>
                    <b-media-aside

                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="light-success"
                      >
                        <feather-icon
                          size="24"
                          icon="DollarSignIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ toFixed(data.resumoSaldos[0].disponivel, 2) }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t('futures.meuAgente.saldoDisponivelOperar') }}

                      </b-card-text>
                      <b-progress
                        :value="data.resumoSaldos[0].disponivelPerc"
                        max="100"
                        class="progress-bar-success"
                        variant="success"
                      />
                    </b-media-body>
                  </b-media>
                </b-button>
              </b-col>
              <b-col
                xl="3"
                sm="6"
                class="mb-xl-0"
              >
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  v-b-tooltip.hover.top="$t('futures.meuAgente.saldoMargemTooltip')"
                  variant="flat-warning"
                  class="btn-icon"
                  block
                >
                  <b-media no-body>
                    <b-media-aside

                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="light-warning"
                      >
                        <feather-icon
                          size="24"
                          icon="BarChart2Icon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ parseFloat(data.resumoSaldos[0].saldoMargem).toFixed(2) }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t('futures.meuAgente.saldoMargem') }}

                      </b-card-text>
                      <b-progress
                        :value="data.resumoSaldos[0].saldoMargemPerc"
                        max="100"
                        class="progress-bar-warning"
                        variant="warning"
                      />
                    </b-media-body>
                  </b-media>
                </b-button>
              </b-col>
              <b-col
                xl="3"
                sm="6"
                class="mb-xl-0"
              >
                <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  v-b-tooltip.hover.top="$t('futures.meuAgente.variacaoOperacoesTooltip')"
                  variant="flat-primary"
                  class="btn-icon"
                  block
                >
                  <b-media no-body>
                    <b-media-aside

                      class="mr-2"
                    >
                      <b-avatar
                        size="48"
                        variant="light-primary"
                      >
                        <feather-icon
                          size="24"
                          icon="ActivityIcon"
                        />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        {{ parseFloat(data.resumoSaldos[0].unPNl).toFixed(2) }}
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t('futures.meuAgente.operacoesEmAberto') }}

                      </b-card-text>
                      <b-progress
                        :value="data.resumoSaldos[0].unPNlPerc"
                        max="100"
                      />
                    </b-media-body>
                  </b-media>
                </b-button>
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-card :title="$t('futures.meuAgente.titleConfiguracoes')">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-tabs
            content-class="pt-1"
            justified
          >
            <b-tab
              v-for="config in configs"
              :key="config.idPerfil"
              :title="config.perfil"
            >
              <h4 class="mb-2">
                {{ $t('futures.meuAgente.primeiroPasso') }}
              </h4>

              <b-row class="mb-2">
                <b-col cols="12">
                  <h5 class="font-medium">
                    {{ config.status === 'Desligado' ? $t('futures.meuAgente.comecarInvestir') : $t('futures.meuAgente.pararDeInvestir') }}
                  </h5>

                  <div class="demo-inline-spacing">
                    <b-form-radio-group v-model="config.status">
                      <b-form-radio
                        name="some-radios"
                        value="Ligado"
                        class="custom-control-success"
                      >
                        {{ $t('futures.meuAgente.sim') }}
                      </b-form-radio>
                      <b-form-radio
                        name="some-radios"
                        value="Desligado"
                        class="custom-control-danger"
                      >
                        {{ $t('futures.meuAgente.nao') }}
                      </b-form-radio>
                    </b-form-radio-group>
                  </div>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <app-collapse
                    accordion
                    type="border"
                  >
                    <app-collapse-item :title="$t('futures.meuAgente.opcoesAvancadas')">
                      <b-row>
                        <b-col lg="6">
                          <b-row>
                            <b-col lg="12">
                              <div>{{ $t('futures.meuAgente.seguirRecomendacaoAgente') }}</div>
                              <div class="demo-inline-spacing mb-2">
                                <b-form-radio-group v-model="config.aceitaWhiteList">
                                  <b-form-radio
                                    name="aceitaWhiteList"
                                    value="true"
                                  >
                                    {{ $t('futures.meuAgente.sim') }}
                                  </b-form-radio>
                                  <b-form-radio
                                    name="aceitaWhiteList"
                                    value="false"
                                  >
                                    {{ $t('futures.meuAgente.nao') }}
                                  </b-form-radio>
                                </b-form-radio-group>
                              </div>

                              <b-form-group
                                :label="$t('futures.meuAgente.aceitarSomenteMoedas')"
                                label-for="aceita-moeda"
                                :description="$t('futures.meuAgente.aceitarSomenteMoedasDescricao')"
                              >
                                <b-form-textarea
                                  v-if="config.aceitaWhiteList=='false'"
                                  v-model="config.whiteList"
                                  @input="config.whiteList = config.whiteList.toUpperCase()"
                                />
                                <b-form-textarea
                                  v-else
                                  v-model="config.whiteListAgente"
                                  disabled
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row>
                            <b-col lg="12">
                              <b-form-group
                                :label="$t('futures.meuAgente.ignorarMoedas')"
                                label-for="ignora-moeda"
                                :description="$t('futures.meuAgente.ignorarMoedasDescricao')"
                              >
                                <b-form-textarea
                                  v-model="config.blackList"
                                  description="Use vírgula para separar"
                                  @input="config.blackList = config.blackList.toUpperCase()"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col lg="6">
                          <b-row v-if="isPermiteConfig('PERMITE_USUARIO_ALTERAR_PORC_CANCELA_ENTRADA')">
                            <b-col lg="12">
                              <b-form-group
                                :label="$t('futures.meuAgente.cancelarOrdemNaoExecutada')"
                                label-for="cancel-compra"
                              >
                                <b-form-select
                                  v-model="config.stopCompraPorcentagem"
                                  :options="stopOptions(config)"
                                  input-id="cancel-compra"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row v-if="isPermiteConfig('PERMITE_USUARIO_ALTERAR_ALAVANCAGEM')">
                            <b-col lg="12">
                              <b-form-group
                                :label="$t('futures.meuAgente.alavancagem')"
                                label-for="alavancagem"
                              >
                                <b-form-select
                                  v-model="config.alavancagem"
                                  :options="alavancagemOptions(config)"
                                  input-id="alavancagem"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                          <b-row v-if="isPermiteConfig('PERMITE_USUARIO_ALTERAR_STOP')">
                            <b-col lg="12">
                              <b-form-group
                                :label="$t('futures.meuAgente.stoplossMax')"
                                label-for="stoploss-max"
                              >
                                <b-form-select
                                  v-model="config.stoplossLimit"
                                  :options="stopVendaLimit"
                                  input-id="stoploss-max"
                                />
                              </b-form-group>
                            </b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </app-collapse-item>
                  </app-collapse>
                </b-col>
              </b-row>

              <hr>
              <h4 class="mb-2">
                {{ $t('futures.meuAgente.segundoPasso') }}
              </h4>
              <b-row class="mb-2">
                <b-col cols="12">
                  <h5 class="font-medium">
                    {{ $t('futures.meuAgente.quantoInvestirPorOperacao') }}

                  </h5>
                  <div class="demo-inline-spacing">
                    <b-form-radio-group v-model="config.tipoValorUSD">
                      <b-form-radio
                        v-if="isPermiteConfig('PERMITE_USUARIO_ENTRADA_VALOR_FIXO')"
                        name="tipo-valor-usd"
                        value="A"
                        @change="trocaTipoValorUSD(config)"
                      >
                        {{ $t('futures.meuAgente.emValor') }}

                      </b-form-radio>
                      <b-form-radio
                        v-if="isPermiteConfig('PERMITE_USUARIO_ENTRADA_VALOR_PERCENTUAL')"
                        name="tipo-valor-usd"
                        value="P"
                        @change="trocaTipoValorUSD(config)"
                      >
                        {{ $t('futures.meuAgente.emPorcentagem') }}

                      </b-form-radio>
                      <b-form-radio
                        v-if="isPermiteConfig('PERMITE_USUARIO_ENTRADA_SEGUIR_AGENTE')"
                        name="tipo-valor-usd"
                        value="I"
                        @change="trocaTipoValorUSD(config)"
                      >
                        {{ $t('futures.meuAgente.emPorcentagemSeguirAgente') }}
                      </b-form-radio>
                    </b-form-radio-group>
                  </div>
                </b-col>
              </b-row>
              <b-row v-if="config.tipoValorUSD != 'I'">
                <b-col lg="4">
                  <b-form-group
                    label-for="precoMaxOrdemUSD"
                  >
                    <label v-if="config.tipoValorUSD=='A'">{{ $t('futures.meuAgente.valorPorOperacao') }}</label>
                    <label v-else>{{ $t('futures.meuAgente.percentualPorOperacao') }}</label>
                    <small
                      v-if="config.tipoValorUSD=='A'"
                      class="text-muted"
                    >
                      <i>  {{ $t('futures.meuAgente.exemploValor') }}</i>
                    </small>
                    <small
                      v-else
                      class="text-muted"
                    >
                      <i>  {{ $t('futures.meuAgente.exemploPercentual') }}</i>
                    </small>
                    <validation-provider
                      #default="{ errors }"
                      :rules="{ required: config.paridadeUSD === 'true' && config.tipoValorUSD != 'I', regex: /^[+]?\d+(\.\d+)?$/, min_value: config.tipoValorUSD==='A' ? 10:0.1, max_value:config.tipoValorUSD==='A'? 99999 : 99 }"
                      :name="config.tipoValorUSD === 'A'? $t('futures.meuAgente.valorPorOperacao'): $t('futures.meuAgente.percentualPorOperacao')"
                    >
                      <b-form-input
                        id="precoMaxOrdemUSD"
                        v-model="config.precoMaxOrdemUSD"
                        :state="errors.length > 0 ? false: null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

            </b-tab>
          </b-tabs>
          <b-col
            v-show="configs"
            cols="12"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-1 mr-1"
              @click.prevent="confirmarSalvar"
            >
              {{ $t('futures.meuAgente.salvarBtn') }}
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, minValue, maxValue } from '@validations'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import {
 BRow, BCard, BCol, BAvatar, BButton, BCardText, VBTooltip, BProgress, BTab, BTabs, BFormRadioGroup, BFormRadio, BFormInput, BFormTextarea, BFormGroup, BFormSelect, BForm, BAlert, BMediaAside, BMediaBody, BMedia, BCardBody, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import utilsMixin from '@/mixins/utilsMixin'

export default {

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [utilsMixin],

  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCard,
    BCol,
    BAvatar,
    BButton,
    BCardText,
    VBTooltip,
    BProgress,
    BTab,
    BTabs,
    BFormRadio,
    BFormRadioGroup,
    AppCollapse,
    AppCollapseItem,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormSelect,
    BForm,
    BAlert,
    BMediaAside,
    BMediaBody,
    BMedia,
    BCardBody,
    BCardHeader,
    BCardTitle,
  },

  props: {
    data: []
  },

  data() {
    return {
      required,
      minValue,
      maxValue,
      stopVendaLimit: [
        {value: null, text: 'Seguir agente'},
        {value: "2", text: '2%'},
        {value: "3", text: '3%'},
        {value: "4", text: '4%'},
        {value: "5", text: '5%'},
      ],
    }
  },

  computed: {

    configs(){
      return this.data.configs
    },
  },

  methods: {

    stopOptions(config) {

      const stopOptionsInt = [
        {value: "0.05", text: 'Se subir 0.05%'},
        {value: "0.1", text: 'Se subir 0.10%'},
        {value: "0.15", text: 'Se subir 0.15%'},
        {value: "0.2", text: 'Se subir 0.20%'},
        {value: "0.3", text: 'Se subir 0.30%'},
        {value: "0", text: 'Não cancelar'}
      ]

      const textAgente = stopOptionsInt.find(v=> v.value==config.stopCompraPorcentagemAgente).text
      stopOptionsInt.unshift({value: null, text: `Seguir agente (${textAgente})`})

      return stopOptionsInt;
    },

    alavancagemOptions(config) {
      const alanvOptionsInt = [
        {value: null, text: 'Seguir agente'},
        {value: 1, text: '1X'},
        {value: 3, text: '3X'},
        {value: 5, text: '5X'},
        {value: 7, text: '7X'},
        {value: 10, text: '10X'},
        {value: 15, text: '15X'},
        {value: 20, text: '20X'},
        {value: 25, text: '25X'},
        {value: 50, text: '50X (Alto risco)'},
        {value: 75, text: '75X (Alto risco)'},
        {value: 100, text: '100X (Alto risco)'}
      ]

      return alanvOptionsInt;
    },

    isPermiteConfig(chave) {
      const setup = this.configs[0].setups.find(c=> c.chave==chave)
      if(!setup){
        return true
      }

      return setup && setup.valor == "true"
    },

    trocaTipoValorUSD(config){
      config.precoMaxTotalUSD = null;
      config.precoMaxOrdemUSD = null;
    },

    confirmarSalvar(id) {
      // validações que não estao sendo pegas pelo validator quando nao esta na aba ativa
      let mensagens = this.validacaoExtra(this.data)
      if (mensagens) {
          this.$swal({
            title: 'Error!',
            html: mensagens,
            icon: 'error',
            confirmButtonText: 'Ok, vou corrigir!',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
      } else {
        this.$swal({
          title: 'Confirmar as alterações',
          text: 'Revise os valores. Ao clicar no botão verde, você vai começar a investir os valores definidos. Também poderá pausar ou alterar a qualquer momento.',
          icon: 'warning',
          confirmButtonText: 'Ok, está tudo certo!',
          showCancelButton: true,
          cancelButtonText: 'Abortar',
          customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            this.submitForm()
          }
        })
      }
    },

    validacaoExtra(data){
      let mensagemValidacao = null
      data.configs.map(function(value, key) {
        if (!/^[+]?\d+(\.\d+)?$/.test(value.precoMaxOrdemUSD) && value.tipoValorUSD != 'I') {
          mensagemValidacao += "O campo 'Valor por operação (USD)' possui um formato inválido. <br>"
        }
      })
      return mensagemValidacao
    },

    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.loadingOverlay = true
          this.$store.dispatch('meu-agente-fut/setMeuAgentePerfilConfig',  this.data.configs)
            .then(() => {
              this.$emit('getMeuAgente', this.$route.params.id)
              this.$swal({
                title: 'Sucesso!',
                text: 'Agente configurado com sucesso',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              this.$swal({
                title: 'Error!',
                text: error.response.data[0].erro,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
          }).finally(() => {
            this.loadingOverlay = false
          });
        }
      })
    },

  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
