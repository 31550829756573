<template>
  <b-tabs pills>
    <b-tab>
      <template #title>
        <feather-icon icon="UserIcon" />
        <span>{{ $t('futures.meuAgente.sobreBtn') }}</span>
      </template>
      <b-card>
        <b-row>
          <b-col
            cols="12"
            xl="9"
            lg="8"
            md="7"
          >
            <meu-agente-edit-tab-profile />
          </b-col>
        </b-row>
      </b-card>
    </b-tab>
    <b-tab active>
      <template #title>
        <feather-icon icon="SettingsIcon" />
        <span>{{ $t('futures.meuAgente.configurarBtn') }}</span>
      </template>
      <meu-agente-edit-tab-operacao />
    </b-tab>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab, BCard, BRow, BCol,
} from 'bootstrap-vue'
import store from '@/store'
import MeuAgenteEditTabProfile from './MeuAgenteEditTabProfile.vue'
import MeuAgenteEditTabOperacao from './MeuAgenteEditTabOperacao.vue'
import meuAgenteStoreModule from './meuAgenteStoreModule'

const MEU_AGENTE_FUT_STORE_MODULE_NAME = 'meu-agente-fut'

export default {
  components: {
    MeuAgenteEditTabProfile,
    MeuAgenteEditTabOperacao,
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
  },

  created() {
    if (!store.hasModule(MEU_AGENTE_FUT_STORE_MODULE_NAME)) store.registerModule(MEU_AGENTE_FUT_STORE_MODULE_NAME, meuAgenteStoreModule)
  },

  beforeDestroy() {
    if (store.hasModule(MEU_AGENTE_FUT_STORE_MODULE_NAME)) store.unregisterModule(MEU_AGENTE_FUT_STORE_MODULE_NAME)
  },

}
</script>
