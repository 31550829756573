<template>
  <div>
    <b-card>
      <b-card-title>
        {{ $t('futures.meuAgente.tituloPlanoAtual') }}
        <b-badge
          variant="success"
          class="badge-glow"
        >
          {{ configUnico.planoVariacaoNome }}
        </b-badge>
      </b-card-title>
      <b-card-sub-title>
        <div>{{ $t('futures.meuAgente.valorMaximo') }}: {{ configUnico.planoLimiteMaximo }} USDT</div>
        <div>{{ $t('futures.meuAgente.valorMinimo') }}: {{ configUnico.planoLimiteMinimo }} USDT</div>
      </b-card-sub-title>
    </b-card>

    <b-card>
      <b-row
        v-for="item in data.resumoSaldos[0].perfis"
        :key="item.perfil"
      >
        <b-col lg="12">
          <b-row>
            <b-col lg="12">
              <h5 class="font-medium">
                {{ $t('futures.meuAgente.saldoCorretora') }}
              </h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              xl="3"
              sm="6"
              class="mb-md-0 mb-xl-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h1 class="font-weight-bolder">
                    {{ toFixed(data.resumoSaldos[0].saldoTotal, 2) }} <small>USDT</small>
                  </h1>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col lg="12">
              <h5 class="font-medium">
                {{ $t('futures.meuAgente.saldoInvestido') }}
              </h5>
            </b-col>
          </b-row>
          <b-row class="mb-2">
            <b-col
              xl="3"
              sm="6"
              class="mb-md-0 mb-xl-0"
            >
              <b-media no-body>
                <b-media-body class="my-auto">
                  <h1 class="font-weight-bolder">
                    {{ toFixed(item.operacao, 2) }} <small>USDT</small>
                  </h1>
                </b-media-body>
              </b-media>
            </b-col>
          </b-row>
          <b-row
            v-if="carteiras.length>0"
            class="mb-3"
          >
            <b-col lg="12">
              <h5 class="font-medium">
                {{ $t('futures.meuAgente.moedasUtilizadas') }}
              </h5>
              <div class="demo-inline-spacing">
                <div
                  v-for="car in carteiras"
                  :key="car.id"
                >
                  <b-avatar
                    :src="getIcon(car.ativo)"
                  />
                  <span class="ml-2">{{ car.ativo }}</span>
                </div>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="!isBloqueiaConfiguracao">
            <b-form>
              <div
                v-for="config in configs"
                :key="config.idPerfil"
              >
                <b-col cols="12">
                  <h5 class="font-medium">
                    {{ config.status=='Desligado'?$t('futures.meuAgente.iniciarOperacoes')
                      : $t('futures.meuAgente.pausarProximasOperacoes') }}
                  </h5>
                  <div class="demo-inline-spacing">
                    <b-form-radio-group v-model="config.status">
                      <b-form-radio
                        name="some-radios"
                        value="Ligado"
                        class="custom-control-success"
                      >
                        {{ $t('futures.meuAgente.sim') }}

                      </b-form-radio>
                      <b-form-radio
                        name="some-radios"
                        value="Desligado"
                        class="custom-control-danger"
                      >
                        {{ $t('futures.meuAgente.nao') }}

                      </b-form-radio>
                    </b-form-radio-group>
                  </div>
                </b-col>
              </div>
              <b-alert
                variant="warning"
                show
                class="mt-2"
              >
                <h4 class="alert-heading">
                  <feather-icon
                    class="mr-25"
                    icon="AlertTriangleIcon"
                  />
                  {{ $t('futures.meuAgente.avisosImportantes') }}

                </h4>
                <div class="alert-body">
                  <div>{{ $t('futures.meuAgente.avisosImportantes1') }}</div>
                  <div>{{ $t('futures.meuAgente.avisosImportantes2') }}</div>
                </div>
              </b-alert>
              <b-col
                v-show="configs"
                cols="12"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-1"
                  @click.prevent="confirmarSalvar"
                >
                  {{ $t('futures.meuAgente.salvarBtn') }}
                </b-button>
              </b-col>
            </b-form>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
/* eslint-disable */
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import {
 BRow, BCard, BCol, BAvatar, BButton, BCardText, VBTooltip, BProgress, BTab, BTabs, BFormRadioGroup, BFormRadio, BTable, BFormCheckbox,
 BFormInput, BFormTextarea, BFormGroup, BFormSelect, BForm, BAlert, BMediaAside, BMediaBody, BMedia, BCardBody, BCardHeader, BCardTitle, BFormSpinbutton, BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import utilsMixin from '@/mixins/utilsMixin'

export default {

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [utilsMixin],

  components: {
    BRow,
    BCard,
    BCol,
    BAvatar,
    BButton,
    BCardText,
    VBTooltip,
    BProgress,
    BTab,
    BTabs,
    BFormRadio,
    BFormRadioGroup,
    AppCollapse,
    AppCollapseItem,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormSelect,
    BForm,
    BAlert,
    BMediaAside,
    BMediaBody,
    BMedia,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormCheckbox,
    BFormSpinbutton,
    BBadge,
  },

  props: {
    data: []
  },

  data() {
    return {
    }
  },

  computed: {
    configs() {
      return this.data.configs
    },

    configUnico() {
      return this.data.configs[0]
    },

    carteiras() {
      return this.configs[0].carteiras
    },

    isBloqueiaConfiguracao() {
      const setup = this.configs[0].setups.find(c=> c.chave=='BLOQUEIA_AGENTE_CONFIGURACAO')
      return setup && setup.valor == "true"
    },
  },

  methods: {

    isPermiteConfig(chave) {
      const setup = this.configs[0].setups.find(c=> c.chave==chave)
      if(!setup){
        return true
      }

      return setup && setup.valor == "true"
    },


    confirmarSalvar(id) {
      this.$swal({
        title: 'Confirmação',
        text: 'Ao clicar no botão verde, você vai começar a investir. Também poderá pausar as novas operações a qualquer momento, entradas parciais das operações em andamento continuaram a serem feitas até finalizar a operação.',
        icon: 'warning',
        confirmButtonText: 'Ok, está tudo certo!',
        showCancelButton: true,
        cancelButtonText: 'Abortar',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.salvar()
        }
      })
    },

    salvar() {
      this.loadingOverlay = true
      this.$store.dispatch('meu-agente-fut/setMeuAgentePerfilConfig',  this.data.configs)
        .then(() => {
          this.$emit('getMeuAgente', this.$route.params.id)
          this.$swal({
            title: 'Sucesso!',
            text: 'Agente configurado com sucesso',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
        })
        .catch(error => {
          this.$swal({
            title: 'Error!',
            text: error.data[0].erro,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
      }).finally(() => {
        this.loadingOverlay = false
      });
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
