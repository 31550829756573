<template>
  <div>
    <b-overlay
      :show="loadingOverlay"
      rounded="sm"
    >
      <b-card v-if="errorMessage">
        <b-row class="mt-10">
          <p v-html="errorMessage" />
        </b-row>
      </b-card>

      <template v-else>
        <meu-agente-edit-tab-operacao-carteira
          v-if="isEstrategiaLoteMinimo('LOTE_CAPITAL_MINIMO')"
          :data="user_data"
        />
        <meu-agente-edit-tab-operacao-padrao
          v-else
          :data="user_data"
        />
      </template>

    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable */
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@/@core/components/app-collapse/AppCollapseItem.vue'
import {
 BRow, BCard, BCol, BAvatar, BButton, BCardText, VBTooltip, BProgress, BOverlay, BTab, BTabs, BFormRadioGroup, BFormRadio, BFormInput, BFormTextarea, BFormGroup, BFormSelect, BForm, BAlert, BMediaAside, BMediaBody, BMedia, BCardBody, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import { subscribeSymbol } from '@/services/binance/fut/binance'
import Ripple from 'vue-ripple-directive'
import utilsMixin from '@/mixins/utilsMixin'
import MeuAgenteEditTabOperacaoPadrao from './MeuAgenteEditTabOperacaoPadrao.vue'
import MeuAgenteEditTabOperacaoCarteira from './MeuAgenteEditTabOperacaoCarteira.vue'


export default {

  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },

  mixins: [utilsMixin],

  components: {
    MeuAgenteEditTabOperacaoPadrao,
    MeuAgenteEditTabOperacaoCarteira,
    BRow,
    BCard,
    BCol,
    BAvatar,
    BButton,
    BCardText,
    VBTooltip,
    BProgress,
    BOverlay,
    BTab,
    BTabs,
    BFormRadio,
    BFormRadioGroup,
    AppCollapse,
    AppCollapseItem,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BFormSelect,
    BForm,
    BAlert,
    BMediaAside,
    BMediaBody,
    BMedia,
    BCardBody,
    BCardHeader,
    BCardTitle,
  },

  data() {
    return {
      loadingOverlay: false,
      user_data: {
        resumoSaldos: []
      },
      errorMessage: null,
    }
  },

  computed: {
    cotacaoBTCUSDT() {
      if(this.$store.state.binanceSpot.tickers && this.$store.state.binanceSpot.tickers['BTCUSDT']){
        return this.$store.state.binanceSpot.tickers['BTCUSDT'].price
      }
    },
  },

  methods: {

    isEstrategiaLoteMinimo() {
      const setup = this.user_data.configs && this.user_data.configs[0].setups.find(c=> c.chave=='LOTE_CAPITAL_MINIMO')
      if(!setup){
        return false // senao tiver resgistro, nao habilita
      }
      return setup && setup.valor == "true"
    },

    getMeuAgente(id) {
      this.loadingOverlay = true
      this.$store.dispatch('meu-agente-fut/getMeuAgenteOperacao', id)
        .then(res => {
          this.user_data = res.data
          this.errorMessage = null

        }).catch(e => {
          this.errorMessage = "API com erro ou não configurada. <a href='/futures/tutorial/adicionando-api-futures'>Clique aqui</a> para obter mais informações"
        
        }).finally(() => {
          this.loadingOverlay = false
        });
    },

  },

  created() {
    this.getMeuAgente(this.$route.params.id)
      subscribeSymbol("BTCUSDT")
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div ::v-deep {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
